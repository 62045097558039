@if (!hidden) {
    <div
        class="container-fluid nav-wrapper text-white p-2 p-md-4 bg-gradient d-flex flex-column gap-2 gap-md-0 flex-md-row">

        <div class="flex-fill d-flex justify-content-between align-items-center gap-2">
            <a href="/"
               class="ms-4 d-md-block d-none" [ngStyle]="{width: '400px'}">
                <img [ngSrc]="'/assets/images/White Logo.svg'"
                     height="40" width="286"
                     priority
                     alt="diagnosou-logo">
            </a>
            @if (showBackBtn) {
                <i class="fa fa-chevron-left m-3 text-white d-md-none d-inline-block" (click)="goBack()"></i>
            }
            <div class="d-flex flex-fill flex-column align-items-center">
                <span class="title">{{ title | translate}}</span>
                @if (subtitle) {
                    <small class="subtitle">{{ subtitle | translate }}</small>
                }
            </div>
<!--            <div disabled [class.redundant]="[0, 1].includes(currentStage)"-->
<!--                 class="placeholder-blank me-md-4 me-0 cursor-default">-->
<!--            </div>-->
            @if (![0, 1].includes(currentStage)) {
                <div class="align-self-center">
                    <app-account-menu></app-account-menu>
                </div>
            }
        </div>

        @if ([0, 1].includes(currentStage)) {
            <input
                type="text"
                placeholder="{{'location_search_empty_view' | translate}}"
                class="search-bar align-self-center"
                #searchInput
            />
        }
    </div>
}
