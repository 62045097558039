import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpServiceBase} from "../classes/HttpServiceBase";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ExamService extends HttpServiceBase {
    protected override baseUrl: string = "/exams";


    constructor(
        private http: HttpClient,
    ) {
        super();
        this.assign(this);
    }

    public retrieve(): Observable<any> {
        return this._http.post(`${this.baseUrl}/list`, {})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrieveFeatured(): Observable<any> {
        return this._http.post(`${this.baseUrl}/featured`, {})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

}
