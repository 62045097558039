import {HttpClient} from "@angular/common/http";
import {TranslateLoader} from "@ngx-translate/core";
import {catchError, Observable, of, take} from "rxjs";
import {map} from "rxjs/operators";
import {reduce, isEmpty} from "lodash";
import {LocalStorageService} from "ngx-webstorage";

export class TranslationLoader implements TranslateLoader {
    private readonly cache: boolean;
    private readonly cacheKeyPrefix: string;
    private readonly ttl: number;

    constructor(
        private http: HttpClient,
        private ls: LocalStorageService,
        options?: TranslationLoaderOptions
    ) {
        this.cache = options?.cache ?? false;
        this.cacheKeyPrefix = options?.cacheKeyPrefix ?? "translations";
        this.ttl = options?.ttl ?? 5 * 60 * 1000;
    }

    clearCache(lang: string): void {
        this.ls.clear(`${this.cacheKeyPrefix}__${lang}`);
    }

    clearCacheIfStale(lang: string): void {
        const cached = this.ls.retrieve(`${this.cacheKeyPrefix}__${lang}`);
        if (Date.now() - (cached?.ts ?? 0) > (this.ttl ?? 0)) {
            this.clearCache(lang);
        }
    }

    getTranslation(lang: string): Observable<any> {
        const translations = this._getTranslationsFromCache(lang);
        if (translations) {
            return translations;
        }

        return this.http.post('/translations', {lang, platform: 'landing-page'})
            .pipe(
                map((res: any) => {
                    if (!res?.translations) {
                        return {};
                    }
                    const translations = reduce(
                        res.translations,
                        (translations: Record<string, string>, entry: any) => {
                            translations[entry.key] = entry.values?.[lang];
                            return translations;
                        },
                        {}
                    )
                    // Cache the translations for future use
                    if (this.cache) {
                        this.ls.store(`${this.cacheKeyPrefix}__${lang}`, {
                            translations,
                            ts: Date.now()
                        });
                    }

                    return translations;
                }),
                catchError((e) => {
                    console.error('Failed to fetch translations', e);
                    return of({})
                })
            );
    }

    private _getTranslationsFromCache(lang: string): Observable<Record<string, string>> | null {
        this.clearCacheIfStale(lang);
        if (!this.cache) {
            return null;
        }
        const cached = this.ls.retrieve(`${this.cacheKeyPrefix}__${lang}`);
        if (isEmpty(cached?.translations ?? {})) {
            this.ls.clear(`${this.cacheKeyPrefix}__${lang}`);
            return null;
        }

        return of(cached.translations).pipe(take(1));
    }
}

interface TranslationLoaderOptions {
    cache?: boolean;
    cacheKeyPrefix?: string;
    ttl?: number;
}
