<!-- <app-revolab-promo-header [device]="!isMobile ? 'desktop' : 'mobile'"
                          [ngClass]="{
                            'sticky': isSticky && isVisible,
                            'visible': isVisible,
                            'hidden': !isVisible,
                            'events-none': !isVisible
                          }"
                          class="d-flex justify-content-center">
</app-revolab-promo-header> -->

<div id="section-logo">
    <div class="w-100 px-3 d-flex p-3 justify-content-between">
        <div class="mt-md-3 header-section">
            @if (isMobile) {
                <img ngSrc="/assets/images/Group.svg"
                     height="{{40 * .5}}" width="{{286 * .53}}"
                     priority alt="diagnosou-logo">
            } @else {
                <img ngSrc="/assets/images/Group.svg"
                     height="40" width="286"
                     priority alt="diagnosou-logo">
            }
        </div>
        <app-account-menu [dark]="true"></app-account-menu>
    </div>
</div>

<div id="section0" #section0>
    <div class="row">
        <div class="col-12 col-xl-5 col-lg-6 d-flex flex-column justify-content-center mobile-wrapper">
            <div class="title mt-3">{{ 'intro_landing_title' | translate }}</div>
            <div class="text mb-4">{{ 'intro_landing_subtitle' | translate }}</div>
            <a class="get-started-a" href="/test-select">
                <button class="btn get-started-btn">{{'intro_landing_btn_text'|translate}}</button>
            </a>
        </div>
        <div class="col-12 col-xl-7 col-lg-6 d-flex justify-content-end p-0">
            @if (isMobile) {
                <img src="/assets/images/main-image-mobile.png" 
                     alt="couple" class="img-fluid" priority>
            } @else {
                <img ngSrc="/assets/images/Group 48096567.png" height="1024" width="1024"
                     alt="intro" class="img-fluid" priority>
            }
            <!-- <img ngSrc="/assets/images/new-image.png" height="1024" width="1024"
            alt="intro" class="img-fluid" priority> -->
        </div>
    </div>
</div>


<div id="newSection1" #newSection1>
    <div class="row" id="firstRowS1">
        <div class="col-12 flex-column d-flex justify-content-center align-items-center mobile-wrapper">
            <div class="title mt-3">{{ 'intro_preventive_title' | translate }}</div>
            <div class="text mb-5" style="max-width: 1100px;">{{ 'intro_preventive_subtitle' | translate }}</div>
        </div>
    </div>
    <div class="row margin-b5 cards-container">
        <div class="col-lg-3 col-6 d-flex justify-content-center mb-4">
            <div class="card">
                <img src="/assets/images/card-image-1.png" height="150" width="150" alt="s2" class="img-fluid">
                <div class="text fw-bold mt-4 mb-4">{{ 'preventive_card1_title' | translate }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-6 d-flex justify-content-center mb-4">
            <div class="card">
                <img src="/assets/images/card-image-2.png" height="150" width="150" alt="s2" class="img-fluid">
                <div class="text fw-bold mt-4 mb-4">{{ 'preventive_card2_title' | translate }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-6 d-flex justify-content-center mb-4">
            <div class="card">
                <img src="/assets/images/card-image-3.png" height="150" width="150" alt="s2" class="img-fluid">
                <div class="text fw-bold mt-4 mb-4">{{ 'preventive_card3_title' | translate }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-6 d-flex justify-content-center mb-4">
            <div class="card">
                <img src="/assets/images/card-image-4.png" height="150" width="150" alt="s2" class="img-fluid">
                <div class="text fw-bold mt-4 mb-4">{{ 'preventive_card4_title' | translate }}</div>
            </div>
        </div>
    </div>
</div>

<div id="newSection2" #newSection2>
    <div class="row" id="firstRowS2">
        <div class="col-12 flex-column d-flex justify-content-center align-items-center mobile-wrapper">
            <div class="title mt-3">{{ 'intro_exams_title' | translate }}</div>
            <div class="text mb-5">{{ 'intro_exams_subtitle' | translate }}</div>
        </div>
    </div>

      <div *ngIf="availableTests.length > 0" class="scroll-container row">
        <button 
        class="scroll-arrow left" 
        *ngIf="(showLeftArrow && (availableTests.length > 4 && !isMobile)) || (showLeftArrow && isMobile && availableTests.length > 1) || (showLeftArrow && isTablet && availableTests.length > 2 && !bigTablet) || (showLeftArrow && bigTablet && availableTests.length > 3)" 
        (click)="scrollLeft()">
        ◀
      </button>
      
        <div #scrollableDiv class="cards-container col-12" (scroll)="onScroll()" [ngClass]="{'justify-content-center': (availableTests.length <= 4 && isDesktop) || (availableTests.length <= 2 && isTablet) || (availableTests.length <= 1 && isMobile) || (bigTablet && availableTests.length <= 3)}">
          <div class="exam-card d-flex flex-column" *ngFor="let test of availableTests">
            <img [src]="test.icon" alt="{{ test.title }}"  width="233" height="235">
            <span class="exam-title mt-3">{{ test.title }}</span>
            <!-- <span class="exam-desc mt-1">{{ test.description }}</span> -->
             <span class="exam-desc mt-2" [innerHTML]="test.description | markdown"></span>
            <div class="d-flex justify-content-between align-items-center pricing">
              <!-- <span class="exam-title mt-1" style="font-size:18px !important">{{ 'exams_price_text' | translate }}</span> -->
              <span class="exam-title mt-1" style="font-size:18px !important">{{test.price.text}}</span>
              <button class="add-btn justify-content-center align-items-center d-flex" (click)="goToLabSelection(test)">
                <img src="/assets/images/price-icon.svg" class="img-fluid">
              </button>
            </div>
          </div>
        </div>
      
        <button 
        class="scroll-arrow right" 
        *ngIf="(showRightArrow && (availableTests.length > 4 && !isMobile)) || (showRightArrow && isMobile && availableTests.length > 1) || (showRightArrow && isTablet && availableTests.length > 2 && !bigTablet) || (showRightArrow && bigTablet && availableTests.length > 3)" 
        (click)="scrollRight()">
        ▶
      </button>
      </div>
</div>

<div id="newSection3" #newSection3>
    <div class="row mt-5 margin-b5 d-flex justify-content-center align-items-center flex-column" id="firstRowS3">
        <div class="title mt-3">{{ 'steps_intro_title' | translate }}</div>

        <div class="col-lg-12 col-6 d-flex justify-content-center align-items-center">
            <div class="step-banner">
                <img src="/assets/images/ChooseTest.svg" alt="" class="img-fluid me-3">
                <h5 class="me-2">{{ 'steps_card1_title' | translate }}{{isMobile ? '' : ':'}}</h5>
                <span>{{ 'steps_card1_subtitle' | translate }}</span>
            </div>
        </div>
        <div class="col-lg-12 col-6 d-flex justify-content-center align-items-center">    
            <div class="step-banner">
                <img src="/assets/images/BookAppointment.svg" alt="" class="img-fluid me-3">
                <h5 class="me-2">{{ 'steps_card2_title' | translate }}{{isMobile ? '' : ':'}}</h5>
                <span>{{ 'steps_card2_subtitle' | translate }}</span>
            </div>
        </div>
        <div class="col-lg-12 col-6 d-flex justify-content-center align-items-center">    
            <div class="step-banner">
                <img src="/assets/images/GetTested.svg" alt="" class="img-fluid me-3">
                <h5 class="me-2">{{ 'steps_card3_title' | translate }}{{isMobile ? '' : ':'}}</h5>
                <span>{{ 'steps_card3_subtitle' | translate }}</span>
            </div>
        </div>
        <div class="col-lg-12 col-6 d-flex justify-content-center align-items-center">    
            <div class="step-banner">
                <img src="/assets/images/GetYourResults.svg" alt="" class="img-fluid me-3">
                <h5 class="me-2">{{ 'steps_card4_title' | translate }}{{isMobile ? '' : ':'}}</h5>
                <span>{{ 'steps_card4_subtitle' | translate }}</span>
            </div>
        </div>    
            <a href="/test-select">
                <button class="btn get-started-btn">{{ 'steps_button_text' | translate }}</button>
            </a>
        
    </div>
</div>

<div id="newSection4" #newSection4>
    <div class="row margin-t5 margin-b5 mobile-wrapper" id="firstRowS4">
        <div class="col-lg-6 col-12">
            <img src="/assets/images/border-image-1.png" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 col-12 justify-content-center d-flex flex-column">
            <div class="title mt-3">{{ 'prevention_section_title1' | translate }}</div>
            <div class="text mb-4">{{ 'prevention_section_subtitle1' | translate }}</div>
        </div>
    </div>
    <div class="row margin-b5 mobile-wrapper" id="secondRowS4">
        <div class="col-lg-6 col-12 justify-content-center d-flex flex-column">
            <div class="title mt-3">{{ 'prevention_section_title2' | translate }}</div>
            <div class="text mb-4">{{ 'prevention_section_subtitle2' | translate }}</div>
        </div>
        <div class="col-lg-6 col-12">
            <img src="/assets/images/border-image-2.png" alt="" class="img-fluid">
        </div>
    </div>
    <div class="row margin-b5 mobile-wrapper" id="thirdRowS4">
        <div class="col-lg-6 col-12">
            <img src="/assets/images/border-image-3.png" alt="" class="img-fluid">
        </div>
        <div class="col-lg-6 col-12 justify-content-center d-flex flex-column">
            <div class="title mt-3">{{ 'prevention_section_title3' | translate }}</div>
            <div class="text mb-4">{{ 'prevention_section_subtitle3' | translate }}</div>
        </div>
    </div>
    <div class="row margin-b5 mobile-wrapper" id="fourthRowS4">
        <div class="col-lg-6 col-12 justify-content-center d-flex flex-column">
            <div class="title mt-3">{{ 'prevention_section_title4' | translate }}</div>
            <div class="text mb-4">{{ 'prevention_section_subtitle4' | translate }}</div>
        </div>
        <div class="col-lg-6 col-12">
            <img src="/assets/images/image (4).png" alt="" class="img-fluid">
        </div>
    </div>
</div>

<div id="newSection5" #newSection5 [ngStyle]="{'background-image': 'url(' + (isMobile ? mobileBackgroundImage : desktopBackgroundImage) + ')'}">
    <div class="row w-100" style="justify-self: center;">
        <div class="col-lg-12 margin-t5 margin-b5 d-flex justify-content-between mobile-view">
            <div class="custom-width">
                <div class="title mt-3">{{ 'healthier_future_title' | translate }}</div>
                <div class="text mt-1" [ngClass]="{'mt-4': isMobile }">{{ 'healthier_future_subtitle_part1' | translate }}</div>
                <div class="text mt-2 mb-4" [ngClass]="{'mb-5': isMobile }">{{ 'healthier_future_subtitle_part2' | translate }}</div>
            </div>
            <a href="/test-select" class="d-flex justify-content-center align-items-center">
                <button  class="btn get-started-btn">{{ 'healthier_future_btn_text' | translate }}</button>
            </a>
        </div>

    </div>
</div>

<div id="newSection6" #newSection6>
    <div class="row w-100 mt-5 mb-5" id="firstRowS6">
        <div class="col-lg-6 col-12 d-flex flex-column">
            <img src="/assets/images/Group.svg" width="214" alt="">
            <div class="small-text mt-4 footer-subtitle">{{ 'footer_subtitle' | translate }}</div>
            <div class="d-flex mt-4 row w-100" id="secondRowS6">
                <a class="small-text custom-col"  href="/">{{ 'footer_menu_item1' | translate }}</a>
                <!-- <a class="small-text custom-col" >{{ 'footer_menu_item2' | translate }}</a> -->
                <!-- <a class="small-text custom-col" >{{ 'footer_menu_item3' | translate }}</a> -->
                <!-- <a class="small-text custom-col" >{{ 'footer_menu_item4' | translate }}</a> -->
                <a class="small-text custom-col" href="/contact">{{ 'footer_menu_item5' | translate }}</a>
            </div>
        </div>
        <div class="col-lg-3 col-6 mt-3">
            <div class="d-flex flex-column">
                <span class="small-title">{{ 'footer_contact_title' | translate }}</span>
                <span class="small-text mt-4" style="font-weight: 400;">contact&#64;diagnosou.com</span>
                <div class="d-flex gap-2 mt-3">
                    <a class="small-text fw-bold" target="_blank" href="https://www.facebook.com/diagnosou"><img src="/assets/images/FB.svg" alt=""></a>
                    <a class="small-text fw-bold" target="_blank" href="https://www.instagram.com/diagnosou_/"><img src="/assets/images/IG.svg" alt=""></a>
                    <a class="small-text fw-bold" target="_blank" href="https://x.com/diagnosou_"><img src="/assets/images/X.svg" alt=""></a>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-6  d-flex flex-column align-items-start mt-3" [ngClass]="{'align-items-center': isMobile }">
            <span class="small-title dl-text">{{ 'footer_app_title' | translate }}</span>
            <a class="mt-4" target="_blank" href="https://apps.apple.com/gr/app/diagnosou/id6736408183"><img src="/assets/images/App store.svg" alt=""></a>
            <a class="mt-3" target="_blank" href="https://play.google.com/store/apps/details?id=com.revotech.diagnosou&hl=en"><img src="/assets/images/Play Store.svg" alt=""></a>
        </div>
    </div>
    <div class="line"></div>
    <div class="row w-100 mb-5" id="lastRowS6">
        <div class="col-lg-6 col-12 d-flex align-items-center" [ngClass]="{'justify-content-center': isMobile}">
            <span class="small-text mt-4">© 2025 Diagnosou. {{ 'footer_copyrights_text' | translate }}</span>
        </div>
        <div class="col-lg-6 col-12 d-flex align-items-center justify-content-end" [ngClass]="{'justify-content-center mt-4 mb-4': isMobile}">
            <div class="d-flex mt-3 row" [ngClass]="{'justify-content-center': isMobile}">
                <a class="small-text custom-col"  href="/tos">{{ 'footer_copyright_menu_item1' | translate }}</a>
                <a class="small-text custom-col" href="/gdpr">{{ 'footer_copyright_menu_item2' | translate }}</a>
                <!-- <a class="small-text custom-col">{{ 'footer_copyright_menu_item3' | translate }}</a> -->
            </div>
        </div>
    </div>
</div>

<app-modal #pendingActionsModal
           [hasHeader]="false"
           bodyClass="position-relative pb-0"
           [hasFooter]="false">
    <ng-container body>
        <app-pending-actions (hasPendingActions)="$event ? pendingActionsModal.show() : pendingActionsModal.hide()">
        </app-pending-actions>
    </ng-container>
</app-modal>
